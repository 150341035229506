<template>
  <div>
    <div
      v-if="dataLoad"
      class="text-center mt-5"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <div v-else>
      <b-card>
        <div v-if="viewData.order_status_id!==6 && viewData.order_status_id!==7">
          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">
                Date: {{ viewData.created_at }}
              </div>
              <b-button
                variant="primary"
                @click="openStatusModal()"
              >
                <span class="text-nowrap"> Change Status </span>
              </b-button>        </div>
          </b-col>
          <div class="container mt-1">
            <b-card>
              <!-- <div class="p-2 text-center text-white text-lg bg-dark rounded-top">
            <span class="text-uppercase">Tracking Order No - </span><span class="text-medium">34VB5540K83</span>
          </div> -->

              <div class="card-body">
                <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                  <div
                    :class="viewData.order_statuses[0].is_current === 1 ? 'step completed' : 'step'"
                  >
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <feather-icon
                          size="21"
                          icon="EditIcon"
                        />

                      </div>
                    </div>
                    <h4 class="step-title">
                      Order Created
                    </h4>
                  </div>
                  <div :class="viewData.order_statuses[1].is_current === 1 ? 'step completed' : 'step'">
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <feather-icon
                          size="21"
                          icon="CheckIcon"
                        />
                      </div>
                    </div>
                    <h4 class="step-title">
                      Order Accepted
                    </h4>
                  </div>
                  <div :class="viewData.order_statuses[2].is_current === 1 ? 'step completed' : 'step'">
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <feather-icon
                          size="21"
                          icon="ClockIcon"
                        />
                      </div>
                    </div>
                    <h4 class="step-title">
                      In Progress
                    </h4>
                  </div>
                  <div :class="viewData.order_statuses[3].is_current === 1 ? 'step completed' : 'step'">
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <feather-icon
                          size="21"
                          icon="TruckIcon"
                        />
                      </div>
                    </div>
                    <h4 class="step-title">
                      Out for delivery

                    </h4>
                  </div>
                  <div :class="viewData.order_statuses[4].is_current === 1 ? 'step completed' : 'step'">
                    <div class="step-icon-wrap">
                      <div class="step-icon">
                        <feather-icon
                          size="21"
                          icon="HomeIcon"
                        />
                      </div>
                    </div>
                    <h4 class="step-title">
                      Order Delivered
                    </h4>
                  </div>
                </div>
              </div>
            </b-card>

          </div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center flex-column "
        >
          <div class="step-canceled my-1">
            <i class="pe-7s-close-circle" />
          </div>
          <div><h2> Order is {{ viewData.order_status_name }}</h2></div>
        </div>
        <div>
          <h3 class="my-2">
            Products
          </h3>
          <b-table
            ref="ProductsTable"
            :items="viewData.carts_data"
            :fields="columns"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
          >
            <template v-slot:cell(image)="value">
              <b-img
                v-img
                width="50"
                :src="value.item.image"
              /></template>
          </b-table>
        </div>
      </b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-card>
            <div>
              <h3 class="my-2">
                Billing Information
              </h3>
            </div>

            <b-row>
              <b-col cols="6">
                <b-row class="mb-1">
                  <b-col cols="6">
                    Sub Total:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.sub_total }}  {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Delivery Fee:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.delivery_fee }}   {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Tax:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.tax }}  {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Total:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bolder h4"
                  >
                    {{ viewData.billing_info.total }}  {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">

                <b-row class="mb-1">
                  <b-col cols="6">
                    Admin Reveneu:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.admin_revenue }}  {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Coupon Discount:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.coupon_discount }}  {{ currentCountry.currency_symbol }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Invoice:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    <a
                      :href="`https://stage.dealmart.me/admin/show_invoice/order/${id}`"
                      target="_blank"
                    >View Invioce   <feather-icon
                      icon="ArrowRightIcon"
                    /></a>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="6">
                    Payment Type:
                  </b-col>
                  <b-col
                    cols="6"
                    class="font-weight-bold"
                  >
                    {{ viewData.billing_info.payment_type }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-card>

            <b-row>
              <b-col cols="5">
                <h3 class="my-2">
                  User Information
                </h3>
                <b-row class="mb-1">
                  <b-col cols="5">
                    Name:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    <router-link :to="{ name: 'show-user-customer', params: { id: viewData.user_info.user_id } }">
                      {{ viewData.user_info.name }}</router-link>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="5">
                    Email:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    {{ viewData.user_info.email }}
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="5">
                    Phone:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    {{ viewData.user_info.phone }}
                  </b-col>
                </b-row>

              </b-col>
              <b-col
                v-if="viewData.delivery_address"
                cols="7"
              >
                <h3 class="my-2">
                  Delivery Address
                </h3>
                <b-row class="mb-1">
                  <b-col cols="5">
                    Address:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    {{ viewData.delivery_address.address }}
                  </b-col>
                </b-row>
                <b-row
                  class="mb-1"
                >
                  <b-col cols="5">
                    Map:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    <a
                      target="_blank"
                      :href="'https://maps.google.com/?q='+viewData.delivery_address.lat+','+viewData.delivery_address.lng+'&ll='+viewData.delivery_address.lat+','+viewData.delivery_address.lng+'&z=19'"
                      class="map-btn"
                    > Show Map </a>
                  </b-col>
                </b-row>
                <b-row
                  v-if="viewData.delivery_address.mobile"
                  class="mb-1"
                >
                  <b-col cols="5">
                    Mobile:
                  </b-col>
                  <b-col
                    cols="7"
                    class="font-weight-bold"
                  >
                    {{ viewData.delivery_address.mobile }}
                  </b-col>
                </b-row>

              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-modal
        ref="modal-status"
        title="Change Order Status"
        @ok.prevent="changeStatus"
      >
        <b-form>
          <b-form-group
            label="status"
            label-for="status"
          >
            <v-select
              v-model="editing_user_id"
              transition=""
              :options="status"
              :filterable="false"
              label="order_status"
              :reduce="status => status.order_status_id"
            />

          </b-form-group>
        </b-form>
        <p class="text-danger">
          {{ errorMsg }}
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  components: { vSelect },

  data() {
    return {
      viewData: {},
      id: this.$route.params.id,
      dataLoad: false,
      errorMsg: '',
      editing_user_id: '',
      status_id: '',
      status: [],
      columns: [
        { key: 'product_name', label: 'Product' },
        { key: 'image', label: 'Image' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'price', label: 'Price' },
        { key: 'number_of_tickets', label: 'Number Of Tickets' },
        { key: 'num_of_free_tickets', label: 'Complementary Tickets' },
        { key: 'status_of_ticket', label: 'Status Of Ticket' },
      ],
    }
  },
  computed: {
    ...mapGetters({ currentCountry: 'app/getCountry' }),

  },
  mounted() {
    this.getdata()
    this.getstatus()
  },
  methods: {
    openStatusModal() {
      this.$refs['modal-status'].show()
    },
    async getstatus() {
      await axios.get('admin/statuses_list').then(res => {
        if (res.status === 200) {
          this.status = res.data?.data.order
        }
      })
    },
    async changeStatus() {
      const formData = {
        order_status_id: this.editing_user_id,
      }
      await axios.post(`admin/change_order_status/${this.id}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs['modal-status'].hide()
          this.getdata()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ooops!!! An Unexpected Internal server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          } setTimeout(() => {
            this.errorMsg = ''
          }, 2500)
        }
      }).finally(() => {
        this.loader = false
      })
    },

    getdata() {
      this.dataLoad = true
      axios.get(`admin/orders/${this.id}`).then(res => {
        this.viewData = res.data.data.order
      }).finally(() => {
        this.dataLoad = false
      })
    },
  },
}
</script>

<style scopped>
body{margin-top:20px;}

.steps .step {
    display: block;
    width: 100%;
    margin-bottom: 35px;
    text-align: center
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 80px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -7px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width:60px;
    height: 60px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 30px;
    line-height: 53px;
    z-index: 5
}

.steps .step .step-title {
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: rgb(43 198 106)
}

.steps .step.completed .step-icon {
    border-color: rgb(43 198 106);
    background-color: rgb(43 198 106);
    color: #fff
}

@media (max-width: 576px) {
    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {
    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {
    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {
    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded, .bg-secondary {
    background-color: #f5f5f5 !important;
}
.pe-7s-close-circle:before{

    color: red;
    width:60px;
    height: 60px;
    border-radius: 50%;
    font-size: 70px;
    line-height: 65px;
    z-index: 5
}
</style>
